import {
  BAYER_VALUE_PROGRAM_ID,
  OTHER_PROGRAM_ID,
  PartnerBadges,
  ProgramBadgeSize,
} from '../../../lib/constants/programs';
import { Trans, useTranslation } from 'react-i18next';

import cx from 'classnames';
import { FC } from 'react';
import { getDynamicProgramColorMap } from '../../../lib/utils/programs';
import ProgramBadge from '../ProgramBadge';
import styles from './PurchaseTableLegend.module.scss';

const PurchaseTableLegend: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['keys'])}>
      <span className={cx(styles['keys__text'])}>
        {t('punctuation.colon', { value: t('purchases.key') })}
      </span>
      <div className={cx(styles['keys__program'])}>
        <ProgramBadge
          className={cx(styles['keys__program-icon'])}
          program={getDynamicProgramColorMap({ id: BAYER_VALUE_PROGRAM_ID })}
          size={ProgramBadgeSize.SM}
        />
        <span className={cx(styles['keys__program-text'])}>
          <Trans i18nKey="bayer-value.programs.fallback-program" components={[<sup />]} />
        </span>
      </div>
      <div className={cx(styles['keys__program'])}>
        <ProgramBadge
          className={cx(styles['keys__program-icon'])}
          program={getDynamicProgramColorMap({ id: OTHER_PROGRAM_ID })}
          size={ProgramBadgeSize.SM}
        />
        <span className={cx(styles['keys__program-text'])}>
          {t(`program.name.${OTHER_PROGRAM_ID}`)}
        </span>
      </div>
      <div className={cx(styles['keys__program'])}>
        <ProgramBadge
          className={cx(styles['keys__program-icon'])}
          program={PartnerBadges.parent}
          size={ProgramBadgeSize.SM}
        />
        <span className={cx(styles['keys__program-text'])}>
          {t(`order-history.partner-key.parent`)}
        </span>
      </div>
      <div className={cx(styles['keys__program'])}>
        <ProgramBadge
          className={cx(styles['keys__program-icon'])}
          program={PartnerBadges.child}
          size={ProgramBadgeSize.SM}
        />
        <span className={cx(styles['keys__program-text'])}>
          {t(`order-history.partner-key.child`)}
        </span>
      </div>
    </div>
  );
};

export default PurchaseTableLegend;

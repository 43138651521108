import { gql } from '../../../../__generated__';

export const GET_CHEQUES = gql(`
query GetCheques($input: ChequesInput!) {
    cheques(input: $input) {
      cheques {
        chequeDate
        chequeNumber
        chequeStatus
        payee
        totalPaymentDollars
      }
    }
  }
`);

import { FC, useState } from 'react';
import axios from 'axios';
import Button from '../../../components/_shared/Button';
import { ButtonThemes } from '../../../lib/constants/components';
import cx from 'classnames';
import { Endpoints } from '../../../lib/constants/endpoints';
import { ForgotPasswordForm } from '../../../lib/constants/forms';
import image from '../../../assets/images/login-landscape.jpg';
import ImageWithContent from '../../../components/ImageWithContent';
import Link from '../../../components/_shared/Link';
import LinkButton from '../../../components/_shared/LinkButton';
import { PageRoutes } from '../../../lib/constants/react-router';
import { Link as RouterLink } from 'react-router-dom';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from '../Login.module.scss';
import TextField from '../../../components/_shared/TextField';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../../../lib/hooks/useFormErrors';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Validations } from '../../../lib/utils/form-validations';

const ForgotPassword: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [, dispatch] = useSnackbarContext();
  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    trigger,
  } = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  useFormErrors(trigger, dirtyFields);

  const onSubmit = (data: ForgotPasswordForm) => {
    const { email } = data;
    axios
      .post(
        Endpoints.RESET_PASSWORD,
        {},
        {
          withCredentials: true,
          params: {
            email,
            lang: language,
          },
          headers: {
            'x-request-id': uuid(),
          },
        }
      )
      .then(() => {
        setSubmitted(true);
      })
      .catch((e) => {
        if (e.response.status === 500) {
          dispatch({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        } else {
          setSubmitted(true);
        }
      });
  };

  return (
    <div className={cx(styles['login'])}>
      <ImageWithContent
        imageSrc={image}
        imageAlt={t('images.farmers')}
        className={cx(styles['content'])}
      >
        <div className={cx(styles['login-container'])}>
          {!submitted ? (
            <div className={cx(styles['login-container__inner'])}>
              <h1 className={cx(styles['login-container__header'])}>
                {t('forgot-password.heading')}
              </h1>
              <p className={cx(styles['login-container__subtitle'])}>
                {t('forgot-password.sub-heading')}
              </p>
              <form
                className={cx(styles['login-container__form'])}
                onSubmit={handleSubmit(onSubmit)}
              >
                <TextField
                  className={cx(styles['login-container__item'], {
                    [styles['login-container__text-field']]: errors.email,
                  })}
                  inputType="email"
                  label={t('form.labels.email')}
                  hasError={!!errors.email}
                  {...register('email', {
                    required: t('form.errors.required'),
                    ...Validations.email(t('form.labels.email'), t),
                  })}
                  // TODO: send hasError & helperText props based on error handling of login api request
                  helperText={errors.email?.message}
                />
                <Button
                  altText={t('form.labels.reset-password')}
                  type="submit"
                  className={cx(styles['login-container__item'])}
                >
                  {t('form.labels.reset-password')}
                </Button>
              </form>
              <Link
                as={RouterLink}
                altText={t('onboarding.return-login-link')}
                to={PageRoutes.GIGYA_PROXY_PAGE}
              >
                {t('onboarding.return-login-link')}
              </Link>
            </div>
          ) : (
            <div className={cx(styles['login-container__inner'])}>
              <h1 className={cx(styles['login-container__header'])}>
                {t('forgot-password.success-heading')}
              </h1>
              <p className={cx(styles['login-container__subtitle'])}>
                {t('forgot-password.success-description')}
              </p>
              <LinkButton
                altText={t('onboarding.button-go-home')}
                as={RouterLink}
                to={PageRoutes.HOME}
                className={cx(styles['login-container__item'])}
                theme={ButtonThemes.SECONDARY}
              >
                {t('onboarding.button-go-home')}
              </LinkButton>
            </div>
          )}
        </div>
      </ImageWithContent>
    </div>
  );
};

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;

import { ButtonThemes } from '../../../../../lib/constants/components';
import cx from 'classnames';
import { FC } from 'react';
import { PageRoutes } from '../../../../../lib/constants/react-router';
import styles from './ResetPasswordComplete.module.scss';
import SuccessCTA from '../../../../../components/SuccessCTA';
import { usePageTitle } from '../../../../../lib/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';

const ResetPasswordConfirmation: FC = () => {
  usePageTitle();
  const { t } = useTranslation();

  return (
    <div className={cx(styles['reset-password'])}>
      <SuccessCTA
        buttonTheme={ButtonThemes.PRIMARY}
        heading={t('account-settings.security.reset-password-success-heading')}
        subHeading={t('account-settings.security.reset-password-success-details')}
        buttonLinkText={t('login.login')}
        buttonRedirectLink={PageRoutes.GIGYA_PROXY_PAGE}
      />
    </div>
  );
};

ResetPasswordConfirmation.displayName = 'ResetPasswordConfirmation';

export default ResetPasswordConfirmation;

import { FC, useEffect, useState } from 'react';
import {
  OrderDirection,
  PurchasePreOrderSortField,
  PurchaseProduct,
} from '../../../../__generated__/graphql';
import { Trans, useTranslation } from 'react-i18next';
import { GET_PURCHASES_TABLE } from '../../../../lib/graphql/Purchases.gql';
import { getRewardEarningPeriodDate } from '../../../../lib/utils/dates';
import Link from '../../../../components/_shared/Link';
import { PageRoutes } from '../../../../lib/constants/react-router';
import RecentPurchases from '../../../../components/BayerValue/RecentPurchases';
import { Link as RouterLink } from 'react-router-dom';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';

interface Props {
  year: number;
}

const RecentPurchasesSection: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [purchaseOrderDirection, setPurchaseOrderDirection] = useState<OrderDirection>(
    OrderDirection.Desc
  );
  const [purchaseOrderBy, setPurchaseOrderBy] = useState<PurchasePreOrderSortField>(
    PurchasePreOrderSortField.Date
  );
  const {
    data: purchaseData,
    loading: loadingPurchases,
    error: purchasesError,
  } = useQuery(GET_PURCHASES_TABLE, {
    variables: {
      orderBy: {
        orderDirection: purchaseOrderDirection,
        orderField: purchaseOrderBy,
      },
      limit: 5,
      filterBy: {
        dateRange: {
          startDate: getRewardEarningPeriodDate(year).start,
          endDate: getRewardEarningPeriodDate(year).end,
        },
      },
    },
  });

  useEffect(() => {
    if (purchasesError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [t, purchasesError, dispatchSnackbar]);

  return (
    <RecentPurchases
      description={
        <Trans
          i18nKey="bayer-value.recent-purchases.description"
          components={[
            <Link
              as={RouterLink}
              to={`/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_MY_PREFERRED_RETAILER} `}
            />,
          ]}
        />
      }
      heading={t('bayer-value.recent-purchases.heading')}
      isLoading={loadingPurchases}
      recentPurchases={
        (purchaseData?.purchaseProducts?.purchaseProducts || []) as PurchaseProduct[]
      }
      viewAllUrl={`/${PageRoutes.ORDER_PURCHASE_HISTORY}`}
      purchaseOrderField={purchaseOrderBy}
      setPurchaseOrderField={setPurchaseOrderBy}
      setPurchaseOrderDirection={setPurchaseOrderDirection}
      purchaseOrderDirection={purchaseOrderDirection}
      year={year}
    />
  );
};

export default RecentPurchasesSection;

import {
  BayerIcon,
  ChevronTopIcon,
  EmailIcon,
  MyBayerIconLight,
  PhoneIcon,
} from '../../assets/icons';
import { ButtonThemes, IconPosition, LinkThemes } from '../../lib/constants/components';
import { FC, useMemo } from 'react';
import { LinkUrls, PageRoutes } from '../../lib/constants/react-router';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../_shared/Button';
import cx from 'classnames';
import { GET_FARM_INFO_NAV } from '../../lib/graphql/Navigation.gql';
import { GET_USER_ONBOARDING_COMPLETE } from '../../lib/graphql/UserInformation.gql';
import { getRegion } from '../../lib/utils/utils';
import Link from '../_shared/Link';
import { Region } from '../../lib/constants/pre-orders';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Footer.module.scss';
import { useQuery } from '@apollo/client';

export interface Props {
  className?: string;
  forceShow?: boolean;
}

const Footer: FC<Props> = ({ className, forceShow }) => {
  const { data: farm } = useQuery(GET_FARM_INFO_NAV, {
    fetchPolicy: 'cache-only',
    partialRefetch: true,
    returnPartialData: true,
  });
  const { data: user } = useQuery(GET_USER_ONBOARDING_COMPLETE, {
    fetchPolicy: 'cache-only',
    partialRefetch: true,
    returnPartialData: true,
  });

  const { t } = useTranslation();

  const displayPreOrder = useMemo(
    () =>
      process.env.REACT_APP_SHOW_PRE_ORDERS &&
      farm?.farm.farmInfo.address?.province &&
      getRegion(farm?.farm.farmInfo.address?.province) === Region.WEST,
    [farm]
  );

  return (
    <footer
      className={cx(className, styles['footer'], {
        [styles['footer--hidden']]: !user?.user?.accountInfo?.onboardingComplete && !forceShow,
      })}
    >
      <div className={cx(styles['footer__top-section'])}>
        <div className={cx(className, styles['footer__logos'])}>
          <Link
            as={RouterLink}
            to={PageRoutes.HOME}
            className={cx(className, styles['footer__logo-wrapper'])}
          >
            <MyBayerIconLight
              className={cx(className, styles['footer__my-bayer-logo'])}
              aria-label={t('general.bayer-logo')}
            />
          </Link>
          <Link
            href={LinkUrls.CROP_SCIENCE_CANADA}
            className={cx(className, styles['footer__logo-wrapper'])}
          >
            <BayerIcon
              className={cx(className, styles['footer__bayer-logo'])}
              aria-label={t('general.bayer-crop-science-logo')}
            />
          </Link>
        </div>

        <div className={cx(styles['footer__top-links-wrapper'])}>
          <div className={cx(styles['footer__links-column'])}>
            <p className={cx(styles['footer__nav-title'])}>{t('footer.quick-links')}</p>
            <nav className={cx(styles['footer__nav'])} aria-label={t('footer.nav.quicklinks')}>
              <ul className={cx(styles['footer__list'])}>
                <li className={cx(styles['footer__nav-item'])}>
                  <Link
                    to={PageRoutes.HOME}
                    as={RouterLink}
                    className={cx(styles['footer__link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                  >
                    {t('footer.links.dashboard')}
                  </Link>
                </li>
                {displayPreOrder && (
                  <li className={cx(styles['footer__nav-item'])}>
                    <Link
                      to={PageRoutes.PRE_ORDERS}
                      as={RouterLink}
                      className={cx(styles['footer__link'])}
                      theme={LinkThemes.TEXT_LINK_DARK}
                    >
                      {t('footer.links.pre-orders')}
                    </Link>
                  </li>
                )}
                <li className={cx(styles['footer__nav-item'])}>
                  <Link
                    to={PageRoutes.ORDER_PURCHASE_HISTORY}
                    as={RouterLink}
                    className={cx(styles['footer__link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                  >
                    {t('footer.links.order-purchase-history')}
                  </Link>
                </li>
                <li className={cx(styles['footer__nav-item'])}>
                  <Link
                    to={PageRoutes.REWARDS}
                    as={RouterLink}
                    className={cx(styles['footer__link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                  >
                    <Trans i18nKey="footer.links.bayer-value-rewards" components={[<sup />]} />
                  </Link>
                </li>
                <li className={cx(styles['footer__nav-item'])}>
                  <Link
                    to={PageRoutes.FAQ}
                    as={RouterLink}
                    className={cx(styles['footer__link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                  >
                    {t('footer.links.faq')}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className={cx(styles['footer__links-column'])}>
            <p className={cx(styles['footer__nav-title'])}>{t('footer.contact-support')}</p>
            <nav className={cx(styles['footer__nav'])} aria-label={t('footer.nav.contact')}>
              <ul className={cx(styles['footer__list'])}>
                <li className={cx(styles['footer__nav-item'], styles['footer__nav-item--support'])}>
                  <Link
                    href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL}
                    className={cx(styles['footer__link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                    icon={PhoneIcon}
                    iconPosition={IconPosition.LEFT}
                  >
                    {t('footer.contact.phone')}
                  </Link>
                </li>
                <li className={cx(styles['footer__nav-item'], styles['footer__nav-item--support'])}>
                  <p className={cx(styles['footer__link-title'])}>{t('footer.contact.ec-title')}</p>
                  <Link
                    href={LinkUrls.MAIL_SUPPORT_WEST}
                    className={cx(styles['footer__link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                    icon={EmailIcon}
                    iconPosition={IconPosition.LEFT}
                  >
                    {t('footer.contact.email-information')}
                  </Link>
                </li>
                <li className={cx(styles['footer__nav-item'], styles['footer__nav-item--support'])}>
                  <p className={cx(styles['footer__link-title'])}>{t('footer.contact.wc-title')}</p>
                  <Link
                    href={LinkUrls.MAIL_SUPPORT_EAST}
                    className={cx(styles['footer__link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                    icon={EmailIcon}
                    iconPosition={IconPosition.LEFT}
                  >
                    {t('footer.contact.email-support')}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className={cx(styles['footer__bottom-section'])}>
        <div className={cx(styles['footer__bottom-inner'])}>
          <div className={cx(styles['footer__bottom-links-wrapper'])}>
            <nav
              className={cx(styles['footer__bottom-nav'])}
              aria-label={t('footer.nav.copyright')}
            >
              <ul className={cx(styles['footer__bottom-list'])}>
                <li className={cx(styles['footer__bottom-nav-item'])}>
                  <Link
                    href={LinkUrls.CONDITIONS_OF_USE}
                    target="_blank"
                    className={cx(styles['footer__bottom-link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                  >
                    {t('footer.links.conditions-of-use')}
                  </Link>
                </li>
                <li className={cx(styles['footer__bottom-nav-item'])}>
                  <Link
                    href={LinkUrls.PRIVACY_STATEMENT}
                    target="_blank"
                    className={cx(styles['footer__bottom-link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                  >
                    {t('footer.links.privacy-statement')}
                  </Link>
                </li>
                <li className={cx(styles['footer__bottom-nav-item'])}>
                  <Link
                    href={LinkUrls.COOKIE_SETTINGS}
                    target="_blank"
                    className={cx(styles['footer__bottom-link'])}
                    theme={LinkThemes.TEXT_LINK_DARK}
                  >
                    {t('footer.links.cookie-info')}
                  </Link>
                </li>
                <li className={cx(styles['footer__bottom-nav-item'])}>
                  <Button
                    className={cx(styles['footer__bottom-link'], 'optanon-show-settings')}
                    theme={ButtonThemes.TEXT_LINK_DARK}
                  >
                    {t('footer.links.cookie-settings')}
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
          <div className={cx(styles['footer__bottom-copyright-wrapper'])}>
            <p className={cx(styles['footer__copyright'])}>
              {t('footer.copyright', { year: new Date().getFullYear() })}
            </p>
          </div>
        </div>
      </div>
      <button
        className={cx(styles['footer__top-button'])}
        type="button"
        onClick={() => {
          scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <ChevronTopIcon className={cx(styles['footer__top-icon'])} aria-hidden="true" />
        <span className={cx(styles['footer__top-text'])}>{t('general.top')}</span>
      </button>
    </footer>
  );
};

Footer.displayName = 'Footer';

export default Footer;

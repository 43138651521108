import {
  LinearChartData,
  LinearChartDataset,
  LinearChartLine,
} from '../../components/LinearChart/LinearChart.types';
import { PurchaseChartAxisKey, PurchaseChartType } from '../constants/purchases';

import { LineColors } from '../constants/charts';
import { PurchaseMetricsQuery } from '../../__generated__/graphql';
import { toNormalizeTranslationKey } from './utils';

/**
 * Convert the data received by the PurchaseMetrics query into the format required by Rechart.
 * @param purchaseMetrics             Query data
 * @param type                        Depending on this value is going to use acreage o mrsp
 * @returns                           Object with labels (years) and the dataset with the values
 */
export const formatPurchaseMetricsData = (
  purchaseMetrics: PurchaseMetricsQuery,
  type: PurchaseChartType
): LinearChartData => {
  const dataset: LinearChartDataset[] = [];
  const lines: LinearChartLine[] = [];

  const keys = purchaseMetrics.purchaseMetrics.breakdown
    .map((item) => item.categoryMetrics.map((metric) => toNormalizeTranslationKey(metric.category)))
    .flat()
    .filter((category, index, self) => self.indexOf(category) === index);

  purchaseMetrics.purchaseMetrics.breakdown.forEach((breakdown) => {
    const record: LinearChartDataset = {
      year: breakdown.year,
    };

    breakdown.categoryMetrics.forEach((categoryBreakdown) => {
      const categoryKey = toNormalizeTranslationKey(categoryBreakdown.category);

      if (!record[categoryKey]) {
        record[categoryKey] =
          (type === PurchaseChartType.ACRE
            ? categoryBreakdown.acrage
            : categoryBreakdown.totalDollarAmount) || 0;
      }
    });

    dataset.push(record);
  });

  const completeDataset = dataset.map((record) => {
    const newRecord = { ...record };
    keys.forEach((key) => {
      if (!(key in newRecord)) {
        newRecord[key] = 0;
      }
    });
    return newRecord;
  });

  let colorIndex: number = 0;
  keys.forEach((key) => {
    const color = LineColors[colorIndex];
    colorIndex = (colorIndex + 1) % LineColors.length;
    const labelKey = `data-visualization.product.main-group.${toNormalizeTranslationKey(key)}`;
    const line: LinearChartLine = {
      key,
      color,
      label: labelKey,
    };
    lines.push(line);
  });

  return { dataset: completeDataset, lines };
};

/**
 * Filters the dataset's keys
 * @param dataset
 * @param filters
 * @returns
 */
const filterDataset = (dataset: LinearChartDataset, filters: string[]): LinearChartDataset =>
  Object.keys(dataset).reduce(
    (acc, key) => {
      if (key === PurchaseChartAxisKey || filters.includes(key)) {
        acc[key] = dataset[key];
      }
      return acc;
    },
    { year: dataset.year } as LinearChartDataset
  );

export const filterPurchaseMetricsData = (
  data: LinearChartData,
  filters: string[]
): LinearChartData => {
  const filteredDataset = data.dataset.map((metric) => filterDataset(metric, filters));
  const filteredLines = data.lines.filter((line) => filters.includes(line.key));

  return { dataset: filteredDataset, lines: filteredLines };
};

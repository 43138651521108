import {
  CornIcon,
  DollarIcon,
  FarmIcon,
  FlareIcon,
  SeedBagIcon,
  StarIcon,
  SuccessCircleIcon,
  TrophyIcon,
} from '../../assets/icons';
import { FC, SVGProps } from 'react';
import { LinkUrls, PageRoutes } from './react-router';

export const BAYER_VALUE_PROGRAM_ID: string = 'bayer-value';
export const OTHER_PROGRAM_ID = 'other';

export enum ProgramBadgeSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum ProgramTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface MainProgram {
  name: string;
  marketingUrl: LinkUrls;
  calculatorUrl: {
    [year: number]: LinkUrls;
  };
}

export interface ProgramColorIcon {
  color: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  theme: ProgramTheme;
  url?: string;
}

export const ProgramColorMap: { [key: string]: ProgramColorIcon } = {
  [BAYER_VALUE_PROGRAM_ID]: {
    color: '#00BCFF',
    Icon: SeedBagIcon,
    theme: ProgramTheme.DARK,
    url: `/${PageRoutes.BAYER_VALUE_PROGRAM}`,
  },
  [OTHER_PROGRAM_ID]: {
    color: '#89D329',
    Icon: CornIcon,
    theme: ProgramTheme.DARK,
  },
};

export const PartnerBadges = {
  parent: {
    color: '#DE0043',
    Icon: FarmIcon,
    theme: ProgramTheme.LIGHT,
  },
  child: {
    color: '#092B3E',
    Icon: FarmIcon,
    theme: ProgramTheme.LIGHT,
  },
};

export const ProgramColorMapFallback: ProgramColorIcon[] = [
  {
    color: '#8C558E',
    Icon: DollarIcon,
    theme: ProgramTheme.LIGHT,
  },
  {
    color: '#006F9B',
    Icon: FlareIcon,
    theme: ProgramTheme.LIGHT,
  },
  {
    color: '#A4E7FF',
    Icon: StarIcon,
    theme: ProgramTheme.DARK,
  },
  {
    color: '#F9780D',
    Icon: TrophyIcon,
    theme: ProgramTheme.LIGHT,
  },
  {
    color: '#10384F',
    Icon: FarmIcon,
    theme: ProgramTheme.LIGHT,
  },
  {
    color: '#443247',
    Icon: SuccessCircleIcon,
    theme: ProgramTheme.LIGHT,
  },
  {
    color: '#DE0043',
    Icon: StarIcon,
    theme: ProgramTheme.LIGHT,
  },
  {
    color: '#FAD6E1',
    Icon: TrophyIcon,
    theme: ProgramTheme.DARK,
  },
];

export enum ProgramType {
  PUBLISHED = 'Published',
  OTHER = 'Other',
  BAYER_VALUE = 'BayerValue'
}

export const HOT_POTATOES: MainProgram = {
  name: 'BayerValue Hot Potatoes',
  marketingUrl: LinkUrls.BAYER_VALUE_HOT_POTATOES,
  calculatorUrl: {
    2023: LinkUrls.BAYER_VALUE_CALCULATOR_HP_2023,
  },
};

export const BAYER_VALUE_EAST: MainProgram = {
  name: 'BayerValue East',
  marketingUrl: LinkUrls.BAYER_VALUE_CALCULATOR_EAST,
  calculatorUrl: {
    2023: LinkUrls.BAYER_VALUE_CALCULATOR_EAST_2023,
  },
};

export const BAYER_VALUE_WEST: MainProgram = {
  name: 'BayerValue West',
  marketingUrl: LinkUrls.BAYER_VALUE_CALCULATOR_WEST,
  calculatorUrl: {
    2023: LinkUrls.BAYER_VALUE_CALCULATOR_WEST_2023,
  },
};

export const MAIN_PROGRAMS = [HOT_POTATOES, BAYER_VALUE_WEST, BAYER_VALUE_EAST];

import { FC, useEffect, useState } from 'react';
import BannersSection from './components/BannersSection/BannersSection';
import BreakdownSection from './components/BreakdownSection/BreakdownSection';
import ChequesSection from './components/ChequesSection/ChequesSection';
import ContactUs from '../../components/BayerValue/ContactUs';
import cx from 'classnames';
import { DisplayTheme } from '../../lib/constants/components';
import EnrolledPrograms from './components/EnrolledPrograms';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../lib/graphql/UserInformation.gql';
import { getCurrentProgramYear } from '../../lib/utils/dates';
import { getProgramYearFromUrl } from '../../lib/utils/programs';
import { hasRewardsAccess } from '../../lib/utils/user';
import InfoBannerSection from './components/InfoBannerSection';
import LandingHero from '../../components/BayerValue/LandingHero';
import { Language } from '../../lib/constants/i18n';
import ProgramPromotionSection from './components/ProgramPromotionSection';
import RecentPurchasesSection from './components/RecentPurchasesSection/RecentPurchasesSection';
import RetreatSection from './components/RetreatSection';
import { StickyRewardsYearPicker } from '../../components/_shared/RewardsYearPicker';
import styles from './BayerRewardsLanding.module.scss';
import { usePageTitle } from '../../lib/hooks/usePageTitle';
import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BayerRewardsLanding: FC = () => {
  usePageTitle('bayer-value');
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [, setSearchParams] = useSearchParams();

  const [selectedYear, setSelectedYear] = useState<number>(getProgramYearFromUrl());
  const { data: userData } = useQuery(GET_USER_REWARDS_ACCESS_QUERY);

  useEffect(() => {
    setSearchParams({ year: selectedYear.toString() });
  }, [selectedYear]);

  return (
    <div className={cx(styles['bayer-rewards-landing'])}>
      <StickyRewardsYearPicker
        selectedYear={selectedYear}
        onSelect={(year) => {
          setSelectedYear(year);
        }}
        title={t('general.rewards-by-year')}
      />
      <LandingHero year={selectedYear} />
      {hasRewardsAccess(userData) ? (
        <>
          <InfoBannerSection year={selectedYear} />
          <BreakdownSection year={selectedYear} />
          {language === Language.En && getCurrentProgramYear() === selectedYear && (
            <ProgramPromotionSection theme={DisplayTheme.BANNER} />
          )}
          <hr className={cx(styles['bayer-rewards-landing__divider'])} />
          {process.env.REACT_APP_SHOW_RETREAT && <RetreatSection year={selectedYear} />}
          <ChequesSection year={selectedYear} />
          <hr className={cx(styles['bayer-rewards-landing__divider'])} />
          <RecentPurchasesSection year={selectedYear} />
          <hr className={cx(styles['bayer-rewards-landing__divider'])} />
        </>
      ) : (
        <EnrolledPrograms year={selectedYear} />
      )}
      <BannersSection />
      <ContactUs />
    </div>
  );
};

BayerRewardsLanding.displayName = 'BayerRewardsLanding';

export default BayerRewardsLanding;

import { DelkabPromotion, SummerSizzlerPromotion } from '../utils/promotions';
import { FlagOptions, useFlags } from './useFlags';
import { DashboardContextActionTypes } from '../contexts/dashboard/DashboardContext.types';
import dayjs from 'dayjs';
import { FlagKeys } from '../constants/launch-darkly';
import { GET_FARM_PROVINCE } from '../graphql/FarmInformation.gql';
import { Language } from '../constants/i18n';
import useDashboardContext from '../contexts/dashboard/useDashboardContext';
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

const desktopFlags: FlagOptions[] = [
  { flagKey: FlagKeys.SUMMER_SIZZLER, defaultValue: false },
  { flagKey: FlagKeys.CALCULATOR, defaultValue: false },
];

export const useDesktopData = (language: string) => {
  const {
    flags: {
      [FlagKeys.SUMMER_SIZZLER]: summerSizzlerEnabled,
      [FlagKeys.CALCULATOR]: calculatorEnabled,
    },
  } = useFlags(desktopFlags);
  const { data: farmData, loading: farmDataLoading } = useQuery(GET_FARM_PROVINCE);
  const [, dispatchDashboard] = useDashboardContext();

  useEffect(() => {
    if (calculatorEnabled) {
      dispatchDashboard({
        type: DashboardContextActionTypes.UpdateDisplayCalculator,
        payload: true,
      });
    } else {
      dispatchDashboard({
        type: DashboardContextActionTypes.UpdateDisplayCalculator,
        payload: false,
      });
    }
  }, [calculatorEnabled]);

  useEffect(() => {
    if (!farmDataLoading && farmData) {
      /**
       * Enables Summer Sizzler promotional banner only for farms based on
       * Alberta, Saskatchewan and Manitoba, also is only visible in english
       */
      if (
        SummerSizzlerPromotion.provinces.some(
          (province) => farmData.farm.farmInfo.address?.province === province
        ) &&
        summerSizzlerEnabled &&
        language === Language.En
      ) {
        dispatchDashboard({
          type: DashboardContextActionTypes.UpdateDisplaySummerSizzlerBanner,
          payload: true,
        });
      } else {
        dispatchDashboard({
          type: DashboardContextActionTypes.UpdateDisplaySummerSizzlerBanner,
          payload: false,
        });
      }

      /**
       * Enables DEKALB DK401TL promotional banner only for farms based on
       * Alberta, Saskatchewan, Manitoba, and before the end of the promotion date
       */
      if (
        DelkabPromotion.provinces.some(
          (province) => farmData.farm.farmInfo.address?.province === province
        ) &&
        dayjs().isBefore(DelkabPromotion.endDate)
      ) {
        dispatchDashboard({
          type: DashboardContextActionTypes.UpdateDisplayDelkabBanner,
          payload: true,
        });
      } else {
        dispatchDashboard({
          type: DashboardContextActionTypes.UpdateDisplayDelkabBanner,
          payload: false,
        });
      }
    }
  }, [farmData, farmDataLoading, summerSizzlerEnabled, language]);
};

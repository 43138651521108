import { FC, useEffect, useState } from 'react';
import { getAdjacentSteps, getFirstStep, getStepById, getSteps } from '../../lib/utils/stepper';
import { SignUpStep, Step, StepComponentProps } from '../../lib/constants/stepper';
import cx from 'classnames';
import { EXISTING_GIGYA_ACCOUNT } from '../../lib/graphql/Gigya.gql';
import { getJwtFromQuery } from '../../lib/utils/jwt';
import { JwtPayload } from '../../lib/types/onboarding';
import { QueryParams } from '../../lib/constants/react-router';
import { SIGN_UP_STEPS } from '../../lib/constants/sign-up';
import { SnackbarContextActionTypes } from '../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../components/SnackbarContainer/Snackbar/Snackbar.types';
import Spinner from '../../components/_shared/Spinner';
import { SpinnerSizes } from '../../lib/constants/components';
import styles from './AccountCreation.module.scss';
import { useLazyQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import useSignUpContext from '../../lib/contexts/sign-up/useSignUpContext';
import useSnackbarContext from '../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { useUrlLanguage } from '../../lib/hooks/useUrlLanguage';

const AccountCreation: FC = () => {
  useUrlLanguage();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [steps, setSteps] = useState<Step[]>([]);
  const [currentStep, setCurrentStep] = useState<Step | null>(getFirstStep(steps));
  const [stepOptions, setStepOptions] = useState<StepComponentProps | undefined>(undefined);
  const [, setSignUpInformation] = useSignUpContext();
  const [queryParams] = useState<JwtPayload | undefined>(
    getJwtFromQuery(searchParams, QueryParams.TOKEN)
  );
  const [checkEmail, { loading }] = useLazyQuery(EXISTING_GIGYA_ACCOUNT);
  const [, dispatchSnackbar] = useSnackbarContext();

  useEffect(() => {
    const signUpSteps = getSteps(SIGN_UP_STEPS, null);
    setSteps(signUpSteps);
    setCurrentStep(getFirstStep(signUpSteps));
  }, []);

  useEffect(() => {
    if (queryParams?.email && queryParams?.accountCode) {
      checkEmail({ variables: { input: { email: queryParams.email } } })
        .then(({ data }) => {
          setSignUpInformation({
            emailExists: data?.existingGigyaAccount.emailExists,
            email: queryParams.email,
            farmId: queryParams.accountCode,
            isAdmin: queryParams.isAdmin || false,
          });
          if (!queryParams?.isAdmin) {
            setCurrentStep(getStepById(steps, SignUpStep.COMPLETE_ACCOUNT));
          }
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  }, [queryParams, steps]);

  useEffect(() => {
    if (currentStep) {
      const { next } = getAdjacentSteps(steps, currentStep?.id);
      if (next) {
        setStepOptions({
          next: () => {
            setCurrentStep(next);
          },
        });
      }
    }
  }, [currentStep, steps]);

  return (
    <div className={cx(styles['account-creation'])}>
      {loading ? (
        <Spinner text={t('general.loading')} size={SpinnerSizes.LG} />
      ) : (
        <div>
          {currentStep?.Component && (
            <currentStep.Component
              next={stepOptions?.next}
              goToStep={(stepId: string) => {
                setCurrentStep(getStepById(steps, stepId));
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

AccountCreation.displayName = 'AccountCreation';

export default AccountCreation;
